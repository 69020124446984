/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { jsx } from "theme-ui"
import { formatDate } from "../utilities/formatDate"
import { formatNewsArticle } from "../utilities/formatRelations"

// components
import Button from "./Button"

// styles
const text = {
  color: "#4d4d4d",
  fontSize: "13px",
}
const newsSourceText = {
  fontWeight: 700,
  fontSize: "17px",
  marginTop: "10px",
}
const articleTitle = {
  fontSize: "17px",
  marginTop: "10px",
  color: "primary",
}
const articleInfo = {
  fontSize: "14px",
  marginTop: "10px",
  marginBottom: "10px",
}

// the article prop can either be a single string or a JSON object
//    if it is a string, it must follow this structure:
//    "{{articleTitle}},.,.{{date}},.,.{{newsType}},.,.{{newsSource}},.,.{{articleImage}},.,.{{articleFile}},.,.{{articleLink}}"
const NewsArticle = ({ article }) => {
  // if it is a string then it came from the relation widget and needs to be parsed into a json object
  if (typeof article === "string") {
    article = formatNewsArticle(article)
  }
  return (
    <div
      sx={{
        display: "flex",
        paddingLeft: "10px",
        jusifyContent: "flex-start",
      }}
    >
      <div sx={{ marginTop: "50px", marginBottom: "50px", maxWidth: "400px" }}>
        <div sx={text}>{formatDate(article.date)}</div>
        <div sx={text}>{article.newsType}</div>
        <div sx={newsSourceText}>{article.newsSource}</div>
        <div sx={articleTitle}>{article.articleTitle}</div>
        {article.articleImage && (
          <img
            sx={{
              marginTop: "20px",
              maxWidth: ["300px", "400px", "400px"],
              maxHeight: "600px",
            }}
            src={article.articleImage}
            alt={article.articleTitle}
          />
        )}

        {article.partyIdentity && (
          <div sx={articleInfo}>
            <span><strong>3rd Party: </strong>{article.partyIdentity}</span>
          </div>
        )}
        {article.dateOfRanking && (
          <div sx={articleInfo}>
            <span><strong>Ranked/Rated: </strong>{article.dateOfRanking}</span>
          </div>
        )}
        {article.applicableTimePeriod && (
          <div sx={articleInfo}>
            <span><strong>For: </strong>{article.applicableTimePeriod}</span>
          </div>
        )} 

        <div sx={{ display: "flex", flexDirection: "column" }}>
          {article.articleFile && (
            <Button
              buttonVariant="blueButton"
              isExternal
              link={article.articleFile}
              color="lightBlue"
              textColor="white"
              text={ article.buttonCopy ? article.buttonCopy : "Download"}
              role="button"
            />
          )}
          
          {article.articleLink && (
            <Button
              buttonVariant="blueButton"
              isExternal
              link={article.articleLink}
              color="lightBlue"
              textColor="white"
              text={ article.buttonCopy ? article.buttonCopy : "Read More"}
              role="button"
            />
          )}
        </div>        
        {article.note && (
          <div sx={text}>
            <p>{article.note}</p>
          </div>
        )} 
      </div>
    </div>
  )
}

export default NewsArticle
