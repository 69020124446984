/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"
import DismissibleExample from "../components/note"

// components
import Hero from "../components/Hero"
import NewsArticleList from "../components/NewsArticleList"

// styles
const mediaContactContainer = {
  backgroundColor: "#e4e4e4",
  display: "flex",
  justifyContent: "center",
}
const mediaContactInnerContainer = {
  width: "1300px",
  borderBottom: "1px solid #afafaf",
  display: "flex",
  justifyContent: "center",
  paddingTop: "20px",
  paddingBottom: "20px",
}

const NewsTemplate = ({ data }) => {
  const d = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Metadata title={d.title} />
      <BaseStyles>
        {d.hero && d.hero.isHero && (
          <Hero
            headline={d.hero.heroHeadline}
            description={d.hero.heroDescription}
          />
        )}
      { d.popup && (
        <div>
        <DismissibleExample popupCopy={d.popupCopy}/>
        </div>
      )}
        <div sx={mediaContactContainer}>
          <div sx={mediaContactInnerContainer}>
            <div
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {d.mediaContact.title}
            </div>
            <a
              sx={{ marginLeft: "10px" }}
              href={`mailto:${d.mediaContact.email}`}
            >
              {d.mediaContact.email}
            </a>
          </div>
        </div>

        <NewsArticleList />
      </BaseStyles>
    </Layout>
  )
}

export default NewsTemplate

NewsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero {
          isHero
          heroHeadline
          heroDescription         
        }
        popup
        popupCopy         
        mediaContact {
          title
          email
        }
        note
      }
    }
  }
`
