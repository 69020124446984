/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import MarkdownContent from "./MarkdownContent"

// styles
const mainContainer = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: "100px",
}
const descriptionContainer = {
  display: [null, null, "flex"],
  justifyContent: "center",
  fontSize: "32px",
  padding: "20px",
  fontWeight: 300,
  width: ["100%", "100%", "1300px"],
  backgroundColor: "white",
}
const headlineContainer = {
  backgroundColor: "rgba(0,157,210,.85)",
  color: "white",
  display: "flex",
  fontSize: "45px",
  fontWeight: 400,
  justifyContent: "center",
  alignItems: "center",
  width: ["100%", "100%", "1300px"],
  paddingTop: ["10px", "10px", "50px"],
  paddingBottom: ["10px", "10px", "50px"],
}

const Hero = ({ headline, description }) => {
  return (
    <div sx={mainContainer}>
      {headline && <div sx={headlineContainer}>{headline}</div>}
      {description && (
        <div sx={descriptionContainer}>
          <div sx={{ maxWidth: "800px", textAlign: "center" }}>
            <MarkdownContent content={description} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Hero

Hero.propTypes = {
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
