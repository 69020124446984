/* eslint-disable func-names */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { useState } from "react"
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { getYear } from "../utilities/formatDate"

// components
import NewsArticle from "./NewsArticle"

// styles
const mainContainer = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}
const columnContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginBottom: "20px",
}
const searchContainer = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#e4e4e4",
  padding: "20px",
}
const yearTitle = {
  backgroundColor: "rgba(120,120,120,.85)",
  color: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  paddingBottom: "30px",
  paddingTop: "30px",
  fontSize: "32px",
}
const button = {
  paddingLeft: "40px",
  paddingRight: "40px",
  margin: "5px",
  border: "1px solid #ccc",
  backgroundColor: "white",
  color: "black !important",
  textDecoration: "none",
  "&:hover": {
    color: "white !important",
    backgroundColor: "primary",
    cursor: "pointer",
  },
}

// !!! this one is not being used at the moment - instead it is NewsArticleList
//    but this one has the buttons and the buttons search and display the news articles
//    from that year rather than displaying all of them at once.

// sorting works by:
//    when page loads ->  1. get all of the articles and sort all of them by date
//                        2. get list of unique years
//    when a year is selected update the useState active year and only display that year
const NewsArticleListWithSearch = () => {
  const [activeYear, setActiveYear] = useState()

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { dataType: { eq: "article" } } }
      ) {
        edges {
          node {
            frontmatter {
              articleTitle
              date
              newsType
              newsSource
              articleImage
              articleFile
              articleLink
              buttonCopy
              partyIdentity
              dateOfRanking
              applicableTimePeriod
              note
            }
          }
        }
      }
    }
  `)

  let articleList = data.allMarkdownRemark.edges

  articleList = sortArticleList(articleList)

  const uniqueYears = getUniqueYears(articleList)

  return (
    <div>
      <div sx={mainContainer}>
        {/* search bar */}
        <div sx={searchContainer}>
          <div sx={{ marginRight: "20px" }}>Show news from</div>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {uniqueYears.map((year, i) => {
              return (
                <a
                  key={i}
                  role="button"
                  tabIndex={0}
                  href={`#${year}`}
                  onKeyDown={() => setActiveYear(year)}
                  sx={button}
                >
                  {year}
                </a>
              )
            })}
          </div>
        </div>
      </div>

      {/* article list */}
      <div>
        {uniqueYears.map((year, i) => {
          return (
            <div key={i}>
              <div sx={yearTitle} id={year}>
                {year}
              </div>
              <div sx={mainContainer}>
                <div sx={columnContainer}>
                  {articleList.map((a, j) => {
                    const article = a.node.frontmatter
                    if (getYear(article.date) === year) {
                      return <NewsArticle article={article} key={j} />
                    }
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewsArticleListWithSearch

// ----------- methods -------------

// get unique years sorted in descending order [2022, 2021, ...]
function getUniqueYears(data) {
  let uniqueYears = []
  data.forEach((article) => {
    const year = getYear(article.node.frontmatter.date)
    if (!uniqueYears.includes(year)) {
      uniqueYears.push(year)
    }
  })
  uniqueYears = uniqueYears.sort().reverse()
  return uniqueYears
}

// takes in array of articles
// returns them sorted by date
function sortArticleList(articleList) {
  articleList.sort(function (a, b) {
    return new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
  })
  return articleList
}
