
// input: "{{openPositionTitle}},.,.{{openPositionPosted}},.,.{{openPositionRequiredExperience}},.,.{{openPositionDescription}},.,.{{openPositionPDF}}"
// return JSON object
export const formatOpenPosition = (position: String) => {
  let splitPosition = position.split(",.,.")
  const formattedPosition = {
    openPositionTitle: splitPosition[0],
    openPositionPosted: splitPosition[1],
    openPositionRequiredExperience: splitPosition[2],
    openPositionDescription: splitPosition[3],
    openPositionPDF: splitPosition[4],
  }

  return formattedPosition
}

// input: "{{articleTitle}},.,.{{date}},.,.{{newsType}},.,.{{newsSource}},.,.{{articleImage}},.,.{{articleFile}},.,.{{articleLink}}"
// returns an article JSON object
export const formatNewsArticle = (article: String) => {
  let splitArticle = article.split(",.,.")
  const formattedArticle = {
    articleTitle: splitArticle[0],
    date: splitArticle[1],
    newsType: splitArticle[2],
    newsSource: splitArticle[3],
    articleImage: splitArticle[4],
    articleFile: splitArticle[5],
    articleLink: splitArticle[6],
  }

  return formattedArticle
}

// input: "{{transactionTitle}},.,.{{loanAmount}},.,.{{location}},.,.{{description}},.,.{{image}},.,.{{isRendered}}"
// returns JSON object
export const formatTransaction = (transaction: String) => {
  let splitTransaction = transaction.split(",.,.")
  const formattedTransaction = {
    transactionTitle: splitTransaction[0],
    loanAmount: splitTransaction[1],
    location: splitTransaction[2],
    description: splitTransaction[3],
    image: splitTransaction[4],
    isRendered: splitTransaction[5],
  }
  return formattedTransaction
}
